import EventEmitter from 'eventemitter3';
import { iframeResizer } from 'iframe-resizer';
import { debounce } from 'underscore';

// Polyfill for Element.classList
import 'classlist.js';

import checkIfVisible from '../util/checkIfVisible';

/**
 * ExpertVoice Recommendations Widget - v1
 *
 * Events:
 * - initialized: the component has been initialized, no data
 * - mount: an iframe has been mounted to the DOM, { iframe }
 * - load: an iframe content has been loaded, {iframe,limit,productCode,recommendationCount,theme}
 * - view: an iframe has been viewed for the first time, { iframe }
 */
class Recommendations extends EventEmitter {
  static postMessageToIFrame(iframe, event, data = {}) {
    if (!iframe || !iframe.contentWindow || !iframe.contentWindow.postMessage) {
      // Iframe does not exist or postMessage is not available, ignoring message
      return;
    }

    const message = `[EV]${JSON.stringify({ event, data })}`;
    iframe.contentWindow.postMessage(message, '*'); // TODO: better origin
  }

  constructor(domain = 'www.expertvoice.com') {
    super();

    this.initialized = false;
    this.domain = domain;
    this.iframes = [];
    this.iframeClass = 'expertvoice-recommendations-display';
  }

  bind() {
    const checkAndHandleIfIframeIsVisible = (iframe) => {
      if (checkIfVisible(iframe)) {
        iframe.setAttribute('data-viewed', 'true');

        // Emit a 'view' event in the iframe internals so it can manage itself
        Recommendations.postMessageToIFrame(iframe, 'view');

        // Emit a 'view' event indicating that the iframe has been viewed for the first time
        this.emit('view', { iframe });
      }
    };

    // Attach a scroll listener to check when the iframe(s) scroll into view
    window.addEventListener('scroll', debounce(() => {
      // Check if any of the not already viewed iframes are visible
      const iframes = document.querySelectorAll(`.${this.iframeClass}:not([data-viewed="true"])`);
      if (iframes.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0, j = iframes.length; i < j; i++) {
          const iframe = iframes[i];
          checkAndHandleIfIframeIsVisible(iframe);
        }
      }

      // Publish a 'parentScroll' event to the iframes so they can track recommendation views
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.iframes.length; i++) {
        const iframe = this.iframes[i];
        Recommendations.postMessageToIFrame(iframe, 'parentScroll', iframe.getBoundingClientRect());
      }
    }, 50));

    // Attach a click listener to publish a 'parentClick' event to the iframe(s)
    document.addEventListener('click', () => {
      // Publish a 'parentScroll' event to the iframes so they can track recommendation views
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.iframes.length; i++) {
        const iframe = this.iframes[i];
        Recommendations.postMessageToIFrame(iframe, 'parentClick');
      }
    });

    // Attach a listener to the internal iframe 'ready' event
    this.on('ready', (data) => {
      // Manage the loaded and empty classes on the iframe once content is loaded
      const { iframe } = data;
      iframe.classList.add('ev-loaded');
      if (!data.recommendationCount) {
        iframe.classList.add('ev-empty');
      } else {
        iframe.style.display = '';
      }

      // Publish a message back to the iframe letting it know some data from the parent page
      Recommendations.postMessageToIFrame(iframe, 'mounted', {
        parent: window.location.href,
      });

      // Emit a 'load' event indicating that the iframe content has been loaded
      this.emit('load', data);
    });

    // Attach a load listener to the iframe and bind events being emitted from the window itself
    this.on('load', ({ iframe }) => checkAndHandleIfIframeIsVisible(iframe));
  }

  init() {
    if (this.initialized) {
      // The plugin has already been initialized so don't do it again
      return;
    }

    this.initialized = true;

    // Bind any events listeners needed for the plugin to function correctly
    this.bind();

    // Emit an 'initialized' event indicating that the plugin has been initialized
    this.emit('initialized');
  }

  load(options = {}) {
    // Ensure the recommendations component is initialized
    this.init();

    const {
      apiKey,
      arrowStyle,
      domain,
      headerBackgroundColor,
      headerTextColor,
      headerTextOption,
      images,
      limit,
      logoStyle,
      onLoad,
      orgId,
      preview,
      productCode,
      target = '#expertvoice-recommendations',
      theme,
    } = options;

    // Build out the query parameters to append to the url
    const params = {};
    if (apiKey) params.apiKey = apiKey;
    if (orgId) params.orgId = orgId;
    if (productCode) params.productCode = productCode;
    if (images !== undefined && !images) params.images = images;
    if (limit) params.limit = limit;
    if (theme) params.theme = theme;
    if (headerBackgroundColor) params.headerBackgroundColor = headerBackgroundColor;
    if (headerTextColor) params.headerTextColor = headerTextColor;
    if (headerTextOption) params.headerTextOption = headerTextOption;
    if (logoStyle) params.logoStyle = logoStyle;
    if (arrowStyle) params.arrowStyle = arrowStyle;

    // Build out the iframe src url
    const queryParams = Object.keys(params)
      .map((k) => `${k}=${params[k]}`)
      .join('&');
    const iframeSrc = `//${domain || this.domain}/widget/recommendations${preview ? '/preview' : ''}?${queryParams}`;

    const container = document.querySelector(target);

    let iframe = container.querySelector(`iframe.${this.iframeClass}`);
    if (iframe) {
      // The iframe already exists. Update the src and let it reload.
      iframe.src = iframeSrc;
    } else {
      // Create the iframe.
      iframe = document.createElement('iframe');
      iframe.src = iframeSrc;
      iframe.id = `ev-recommendations-${Math.round(Date.now() * Math.random())}`;
      iframe.title = 'Recommendations from experts who use this product.';
      iframe.width = '100%';
      iframe.allowFullscreen = true;
      iframe.allowTransparency = true;
      iframe.frameBorder = 0;
      iframe.scrolling = 'no';
      iframe.style.display = 'none';
      iframe.classList.add(this.iframeClass);

      // Bind up to any messages published from the iframe to the window.parent.postMessage
      // TODO: this being in the 'load' method causes problems when multiple iframes are present.
      // TODO: it should be done in 'init' with event handler identifying the originating iframe.
      const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
      const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
      window[eventMethod](messageEvent, (e) => this.onMessageFromIframe(iframe, e));

      // Bind to the load event and invoke the onLoad callback for this iframe
      if (typeof onLoad === 'function') {
        this.on('load', ({ iframe: loadedIframe, ...data }) => {
          if (loadedIframe === iframe) {
            onLoad(data);
          }
        });
      }

      container.appendChild(iframe);

      // Bind the iframe-resizer to work with the iframe
      this.iframes.push(...iframeResizer({ checkOrigin: false }, iframe));

      // Emit a 'mount' event indicating that the iframe has been mounted on the DOM
      this.emit('mount', { iframe });
    }
  }

  onMessageFromIframe(iframe, event) {
    if (!event?.data?.startsWith?.('[EV]')) {
      // Ignore the event since it is not part of the recommendations widget functionality
      return;
    }

    // Parse out the JSON data from the event
    let eventData = {};
    try {
      eventData = JSON.parse(event.data.substr(4));
    } catch (e) {
      // Ignore error
    }

    // Emit the event from the child window
    if (eventData.event) {
      this.emit(eventData.event, { iframe, ...eventData.data });
    }
  }
}

// Construct the default instance of the component
const widget = new Recommendations();

// TODO: Configure this to expose on the window through the webpack config
window.EV = window.EV || {};
window.EV.Recommendations = widget;

// Auto-load if the settings are configured on the EV object
if (window.EV.recoSettings) {
  window.EV.Recommendations.load(window.EV.recoSettings);
}

export default widget;
