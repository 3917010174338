/* eslint-disable no-unused-vars */
/**
 * Checks if an element is visible on the page (within the scroll of the parent window)
 *
 * @param el the element
 * @param parentRect bounding client rect of parent element
 * @param tolerance Percentage of the element needed to be in view to be considered visible
 * @returns {boolean}
 */
const checkIfVisible = (el, parentRect = {}, tolerance = 0.85) => {
  // TODO: honor the parentRect so the iframe is aware of the parent scroll position

  const vh = window.innerHeight || document.documentElement.clientHeight;
  const vw = window.innerWidth || document.documentElement.clientWidth;
  const ah = vh / tolerance; // Adjusted viewport height w/ tolerance
  const aw = vw / tolerance; // Adjusted viewport width w/ tolerance

  const rect = el.getBoundingClientRect();

  const outTop = rect.top < (vh - ah);
  const outLeft = rect.left < (vw - aw);
  const outBottom = rect.bottom > ah;
  const outRight = rect.right > aw;

  let isVisible = false;
  if (rect.width && rect.height) {
    if (outTop && outBottom) {
      // Element is larger than the viewport and is in view
      isVisible = true;
    } else if (!outTop && !outLeft && !outBottom && !outRight) {
      // Element is fully in the viewport
      isVisible = true;
    }
  }

  // eslint-disable-next-line max-len
  // console.log(`${isVisible}; vh=${vh}; vw=${vw}; ah=${ah}; aw=${aw}; ot=${outTop}; or=${outRight}; ob=${outBottom}; ol=${outLeft}; rect=`, rect);

  return isVisible;
};

export default checkIfVisible;
